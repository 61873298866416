import React from "react";
import { Loader as DefaultLoader, Flex } from "@mantine/core";

const Loader = () => {
  return (
    <Flex p={40} align="center" justify="center">
      <DefaultLoader color="pink" size="xl" />
    </Flex>
  );
};

export default Loader;
