import React, { useState } from "react";
import { Button, Flex, Input, Modal, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { FaCheck, FaExclamationCircle, FaImage } from "react-icons/fa";
import { defaultGreen, defaultPink } from "../constants";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { resetState, uploadServiceImage } from "../state/slices/donationSlice";
import { SelectedDonationTier } from "../interfaces";

interface CreateCharacterModalProps {
  isOpen: boolean;
  description: string;
  setIsOpen: (param: boolean) => void;
  setSelectedTier: (param: SelectedDonationTier) => void;
  setIsDonatedModalOpen: (param: boolean) => void;
  setDescription: (param: string) => void;
}

const CreateCharacterModal: React.FC<CreateCharacterModalProps> = ({
  isOpen,
  description,
  setIsOpen,
  setSelectedTier,
  setDescription,
  setIsDonatedModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const [accepted, setAccepted] = useState(false);
  const { email, name } = useAppSelector((state) => state.user);
  const { uploadedImage } = useAppSelector((state) => state.donations);

  const handleImageUpload = async (file: any) => {
    setAccepted(true);
    await dispatch(uploadServiceImage(file[0]));
  };

  const handleCharacterCreate = async () => {
    setSelectedTier({
      price: "100.00",
      title: "Get your own character",
      description: `Character creation donation from ${name} (${email}), find the image attached below`,
      success:
        "Thank you. We will be coming back with your character soon to your mail inbox",
      failed: "Donation failed. Try again.",
      message: "Stacey donation - get your own character",
      donationType: "Character Creation",
      image: uploadedImage,
      amount: "100.00",
      preferredEmail: description,
    });
    setIsOpen(false);
    setAccepted(false);
    setIsDonatedModalOpen(true);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
        setAccepted(false);
        setDescription("");
        dispatch(resetState());
      }}
      title="Character Creation"
      radius="lg"
      centered
    >
      <Text size="xs" c="gray" mb={5}>
        Provide us an image for us to create your character and an e-mail for
        the delivery:
      </Text>
      <Input
        type="email"
        value={description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setDescription(event.target.value);
        }}
        radius="lg"
        my={10}
        placeholder="Your email"
      />
      <Dropzone
        onDrop={(file) => {
          handleImageUpload(file);
        }}
        accept={IMAGE_MIME_TYPE}
        radius="lg"
        multiple={false}
      >
        {" "}
        <Dropzone.Accept>
          <Flex align="center" justify="center">
            <FaCheck />
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex align="center" justify="center">
            <FaExclamationCircle />
            <Text size="sm" c={accepted ? defaultGreen : defaultPink}>
              Image not accepted
            </Text>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex align="center" direction="column">
            {accepted ? (
              <FaCheck color={defaultGreen} size={30} />
            ) : (
              <FaImage color={defaultPink} size={30} />
            )}

            <Text size="sm" c={accepted ? defaultGreen : defaultPink}>
              {" "}
              {accepted ? "Image accepted" : "Drop your image here"}
            </Text>
          </Flex>
        </Dropzone.Idle>
      </Dropzone>
      <Button
        mt={8}
        radius="xl"
        color={defaultPink}
        fullWidth
        onClick={() => handleCharacterCreate()}
        disabled={!accepted || description === ""}
      >
        Proceed to payment
      </Button>
    </Modal>
  );
};

export default CreateCharacterModal;
