import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Image,
  Modal,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import {
  FaDonate,
  FaFileVideo,
  FaMoneyBill,
  FaPaintRoller,
  FaPlusCircle,
  FaTrash,
  FaTwitter,
} from "react-icons/fa";
import { useForm } from "@mantine/form";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  createChapterWithImage,
  createEpisodeWithImage,
  deleteChapter,
  deleteEpisode,
  getEpisodes,
} from "../../state/slices/episodeSlice";
import Loader from "../../components/Loader";
import {
  Art,
  Chapter,
  Donation,
  Episode,
  EpisodeFetch,
} from "../../interfaces";
import moment from "moment";
import {
  completeDonation,
  getDonations,
} from "../../state/slices/donationSlice";
import FanArtCard from "../../components/FanartCard";
import { getAllFanart } from "../../state/slices/fanartSlice";
import { defaultPink } from "../../constants";

interface ChapterFormDetails {
  name: string;
  image: File | null;
  description: string;
}

interface ChapterFormWithImageDetails {
  id: string;
  name: string;
  thumbnail: File | null;
  video: string;
  description: string;
}

const Admin = () => {
  const dispatch = useAppDispatch();
  const { loading, episodes } = useAppSelector((state) => state.episodes);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const { donations } = useAppSelector((state) => state.donations);
  const { allFanart, loading: fanartLoading } = useAppSelector(
    (state) => state.fanart
  );

  const [opened, setIsOpened] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [episodesModelOpened, setEpisodesModelOpened] = useState(false);
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([]);
  const [mode, setMode] = useState<"CHAPTER" | "EPISODE">("CHAPTER");

  const isValidUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  const chapterForm = useForm({
    initialValues: {
      name: "",
      image: null,
      description: "",
    },

    validate: {
      name: (value: string) =>
        value?.length < 1 ? "Name should be longer" : null,
      image: (value: string) =>
        value === null ? "Image cannot be empty" : null,
      description: (value: string) =>
        value?.length < 4 ? "Description should be longer" : null,
    },
  });

  const episodeForm = useForm({
    initialValues: {
      name: "",
      thumbnail: null,
      video: "",
      description: "",
    },

    validate: {
      name: (value: string) =>
        value?.length < 1 ? "Name should be longer" : null,
      thumbnail: (value: string) =>
        value === null ? "Thumbnail cannot be empty" : null,
      video: (value: string) => (isValidUrl(value) ? null : "Invalid URL"),
      description: (value: string) =>
        value?.length < 4 ? "Description should be longer" : null,
    },
  });

  const handleCreateChapter = (values: ChapterFormDetails) => {
    setIsOpened(true);
    dispatch(
      createChapterWithImage({
        name: values.name,
        description: values.description,
        file: values.image,
      })
    );
    if (!loading) {
      setIsOpened(false);
      chapterForm.reset();
    }
  };

  const handleCreateEpisode = async (values: ChapterFormWithImageDetails) => {
    await dispatch(
      createEpisodeWithImage({
        id: selectedChapter,
        name: values.name,
        video: values.video,
        description: values.description,
        file: values.thumbnail,
      })
    );
    setSelectedChapter("");
    if (!loading) {
      setIsOpened(false);
      episodeForm.reset();
      setSelectedChapter("");
    }
  };

  const handleEpisodeDelete = (values: EpisodeFetch) => {
    dispatch(deleteEpisode(values));
  };

  const handleChapterDelete = (value: string) => {
    dispatch(deleteChapter(value));
  };

  const renderDonationTypeImage = (donation: Donation) => {
    switch (donation.donationType) {
      case "Character Creation":
        return (
          <Image
            height={50}
            radius="md"
            fit="contain"
            src={donation.image}
            mr={10}
          />
        );
      case "Character Appearence":
        return (
          <Image
            height={50}
            radius="md"
            fit="contain"
            src={donation.image}
            mr={10}
          />
        );
      case "Tweet":
        return (
          <Avatar mr={10} color="blue" radius="sm">
            <FaTwitter size="1.5rem" />
          </Avatar>
        );
      default:
        return (
          <Avatar mr={10} color="green" radius="sm">
            <FaMoneyBill size="1.5rem" />
          </Avatar>
        );
    }
  };

  useEffect(() => {
    dispatch(getEpisodes());
    dispatch(getDonations());
    dispatch(getAllFanart());
  }, [dispatch]);
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setIsOpened(false);
          chapterForm.reset();
        }}
        title={mode === "CHAPTER" ? "Create Season" : "Create Episode"}
        radius="lg"
        centered
        closeOnClickOutside={false}
      >
        {mode === "CHAPTER" ? (
          <form
            onSubmit={chapterForm.onSubmit((values: any) =>
              handleCreateChapter(values)
            )}
          >
            <TextInput
              radius="xl"
              withAsterisk
              label="Name"
              placeholder="Season Name"
              {...chapterForm.getInputProps("name")}
            />
            <Textarea
              mt={10}
              radius="xl"
              withAsterisk
              label="Description"
              placeholder="Season Description"
              {...chapterForm.getInputProps("description")}
            />
            <FileInput
              mt={10}
              radius="xl"
              label="Season Image"
              accept="image/png,image/jpeg"
              withAsterisk
              {...chapterForm.getInputProps("image")}
            />
            <Button
              loading={loading}
              mt={12}
              color="pink"
              radius="xl"
              fullWidth
              type="submit"
            >
              Create Season
            </Button>
          </form>
        ) : (
          <form
            onSubmit={episodeForm.onSubmit((values: any) => {
              handleCreateEpisode(values);
            })}
          >
            <TextInput
              radius="xl"
              withAsterisk
              label="Episode Name"
              placeholder="Episode Name"
              {...episodeForm.getInputProps("name")}
            />
            <FileInput
              mt={10}
              radius="xl"
              label="Episode Thumbnail"
              withAsterisk
              accept="image/png,image/jpeg"
              {...episodeForm.getInputProps("thumbnail")}
            />
            <TextInput
              mt={10}
              radius="xl"
              withAsterisk
              label="Episode Video"
              placeholder="Episode Video"
              {...episodeForm.getInputProps("video")}
            />
            <Textarea
              mt={10}
              radius="xl"
              withAsterisk
              label="Episode Description"
              placeholder="Episode Description"
              {...episodeForm.getInputProps("description")}
            />
            <Button
              loading={loading}
              mt={12}
              color="pink"
              radius="xl"
              fullWidth
              type="submit"
            >
              Create Episode
            </Button>
          </form>
        )}
      </Modal>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container mt={10}>
        <Tabs color="pink" variant="default" defaultValue="videos">
          <Tabs.List>
            <Tabs.Tab value="videos" leftSection={<FaFileVideo />}>
              Videos
            </Tabs.Tab>
            <Tabs.Tab value="donations" leftSection={<FaDonate />}>
              Donations
            </Tabs.Tab>
            <Tabs.Tab value="fanart" leftSection={<FaPaintRoller />}>
              Fanart
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="videos">
            <Box mt={30}>
              <Flex direction="row">
                <Button
                  leftSection={<FaPlusCircle />}
                  color="blue"
                  onClick={() => {
                    setMode("CHAPTER");
                    setIsOpened(true);
                  }}
                  mr={10}
                  radius="xl"
                  fullWidth
                  loading={loading}
                >
                  Create Season
                </Button>
              </Flex>
            </Box>
            <Divider size="xl" mt={20} label="Season & Episodes" />
            <Modal
              opened={episodesModelOpened}
              onClose={() => {
                setEpisodesModelOpened(false);
                setSelectedEpisodes([]);
              }}
              title="Season Episodes"
              radius="lg"
              centered
            >
              <>
                {selectedEpisodes.map((e: Episode) => {
                  return (
                    <Box>
                      <Flex>
                        <Flex direction="row">
                          <Image
                            radius="xs"
                            height="40"
                            width="40"
                            fit="contain"
                            src={e.thumbnail}
                            mr={10}
                          />
                        </Flex>
                        <Flex direction="column" justify="center">
                          <Text size="xs">{e.name}</Text>
                          <Text color="dimmed" size="xs">
                            {e.description}
                          </Text>
                          <Text color="dimmed" size="xs">
                            {moment(e.createdAt).format("DD-MM-YYYY hh:mm A")}
                          </Text>
                        </Flex>
                      </Flex>
                      <Divider />
                    </Box>
                  );
                })}
              </>
            </Modal>
            {loading ? (
              <Loader />
            ) : (
              <>
                {(episodes || []).map((chapter: Chapter) => {
                  return (
                    <Card mt={8} bg={defaultPink} c="white" radius="lg">
                      <Flex direction="row">
                        <Image
                          radius="md"
                          h={50}
                          w={120}
                          src={chapter.image}
                          height={180}
                          fit="contain"
                          alt="thumbnail"
                        />
                        <Flex
                          w="100%"
                          direction="row"
                          align="center"
                          justify="space-between"
                        >
                          <Box ml={10}>
                            <Text>{chapter.name}</Text>
                            <Text c="dark" size="sm">
                              {chapter.description}
                            </Text>
                          </Box>
                          <Flex>
                            <ActionIcon
                              size="md"
                              color="green"
                              radius="xl"
                              mr={4}
                              onClick={() => {
                                setMode("EPISODE");
                                setSelectedChapter(chapter._id);
                                setIsOpened(true);
                              }}
                            >
                              <FaPlusCircle />
                            </ActionIcon>
                            <ActionIcon
                              size="md"
                              color="red"
                              radius="xl"
                              mr={4}
                              onClick={() => {
                                handleChapterDelete(chapter._id);
                              }}
                            >
                              <FaTrash />
                            </ActionIcon>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider mt={10} />
                      {chapter.episodes.length ? (
                        <>
                          {(chapter.episodes || []).map((episode: Episode) => {
                            return (
                              <Card radius="md" mt={8}>
                                <Flex direction="row">
                                  <Image
                                    radius="md"
                                    h={50}
                                    w={120}
                                    src={episode.thumbnail}
                                    height={180}
                                    fit="contain"
                                    alt="thumbnail"
                                  />
                                  <Flex
                                    w="100%"
                                    direction="row"
                                    align="center"
                                    justify="space-between"
                                  >
                                    <Box ml={10}>
                                      <Text c="dark">{episode.name}</Text>
                                      <Text c="gray" size="sm">
                                        {episode.description}
                                      </Text>
                                      <Text c="gray" size="xs">
                                        {moment(episode.createdAt).format(
                                          "DD-MMM-YYYY hh:mm A"
                                        )}
                                      </Text>
                                    </Box>
                                    <Box>
                                      <ActionIcon
                                        size="md"
                                        color="red"
                                        radius="md"
                                        mr={4}
                                        onClick={() =>
                                          handleEpisodeDelete({
                                            episodeId: episode._id,
                                            chapterId: chapter._id,
                                          })
                                        }
                                      >
                                        <FaTrash />
                                      </ActionIcon>
                                    </Box>
                                  </Flex>
                                </Flex>
                              </Card>
                            );
                          })}
                        </>
                      ) : (
                        <Text>No episodes</Text>
                      )}
                    </Card>
                  );
                })}
              </>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="donations">
            <Divider mt={20} label="Services" />
            {/* <Table>
              <thead>
                <tr>
                  <th>Donator</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Amount Paid</th>
                  <th>Comments</th>
                  <th>Donated On</th>
                </tr>
              </thead>
              <tbody>{donationRows}</tbody>
            </Table> */}

            {donations.map((donation: Donation) => {
              return (
                <Card key={donation._id} radius="lg" mt={8} mb={8}>
                  <Flex align="center">
                    {renderDonationTypeImage(donation)}
                    <Box>
                      <Text c="gray">{donation.donationType}</Text>
                      <Text c="gray">{donation.name}</Text>
                    </Box>
                  </Flex>

                  <Text mt={10} mb={10} size="xs">
                    Email: {donation.email}
                  </Text>
                  <Text size="xs">Message: {donation.message}</Text>
                  <Text size="xs">Amount: ${donation.amount}</Text>
                  <Text size="xs">
                    Requested on:{" "}
                    {moment(donation.createdAt).format("YYYY-MMM-DD hh:mm A")}
                  </Text>
                  {donation.donationType !== "Custom Donation" && (
                    <Button
                      mt={6}
                      color={donation.completed ? "blue" : "green"}
                      radius="xl"
                      onClick={() => dispatch(completeDonation(donation._id))}
                    >
                      {donation.completed ? "Completed" : "Complete"}
                    </Button>
                  )}
                </Card>
              );
            })}
          </Tabs.Panel>

          <Tabs.Panel value="fanart">
            <Divider mt={20} label="Fanart" />
            {fanartLoading ? (
              <Flex direction="row" justify="center" align="center">
                <Loader />
              </Flex>
            ) : (
              <Grid mt={10}>
                {(allFanart || []).map((art: Art) => {
                  return (
                    <Grid.Col span={{ base: 4, xs: 12, sm: 6, md: 4 }}>
                      <FanArtCard
                        _id={art._id}
                        image={art.image}
                        name={art.name}
                        date={art.createdAt}
                        approved={art.approved}
                      />
                    </Grid.Col>
                  );
                })}
              </Grid>
            )}
          </Tabs.Panel>
        </Tabs>
      </Container>
    </>
  );
};

export default Admin;
