import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Flex,
  Image,
  NumberInput,
  Text,
} from "@mantine/core";
import { BsCurrencyDollar } from "react-icons/bs";
import donationDrag from "../../images/DonationDrag.png";
import donationDrop from "../../images/DonationDrop.png";
import { defaultPink, defaultYellow } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import PayModal from "../../popups/PayModal";
import { submitDonation } from "../../state/slices/donationSlice";

interface DragImageProps {
  isAuthenticated: boolean | null;
}

const DragImage: React.FC<DragImageProps> = ({ isAuthenticated }) => {
  const dispatch = useAppDispatch();
  const { email, name } = useAppSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState(false);
  const [amount, setAmount] = useState<string>("1");
  const [isDonatedModalOpen, setIsDonatedModalOpen] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedImageSrc = e.dataTransfer.getData("imageSrc");
    if (droppedImageSrc) {
      setIsVisible(true);
    }
  };

  const handleDragStart = (e: any) => {
    e.dataTransfer.setData("imageSrc", e.target.src);
  };

  const handleOnDonationSuccess = async () => {
    await dispatch(
      submitDonation({
        message: `Custom donation from ${name} (${email}) for $${amount}`,
        donationType: "Custom Donation",
        image: "No Image",
        amount: amount,
        preferredEmail: email,
      })
    );
    setIsVisible(false);
    setAmount("1");
    setIsDonatedModalOpen(false);
  };

  return (
    <>
      <PayModal
        price={amount}
        title="Custom Donation"
        isCompleted="NOT"
        isOpen={isDonatedModalOpen}
        setIsCompleted={() => "NOT"}
        setIsOpen={setIsDonatedModalOpen}
        description={`Custom donation from ${name} (${email}) for ${amount}`}
        successMessage="Thank you for your donation"
        failedMessage="Donation failed"
        onSuccess={handleOnDonationSuccess}
      />
      <Card bg={defaultPink} radius="lg" mt={4}>
        <Box>
          <Flex justify="space-between" align="center" gap="xl" direction="row">
            <Box>
              {!isVisible && (
                <Image
                  fit="contain"
                  height={100}
                  width={100}
                  src={donationDrag}
                  draggable="true"
                  onDragStart={handleDragStart}
                />
              )}
            </Box>

            <Flex onDrop={handleDrop} onDragOver={handleDragOver}>
              <Image
                fit="contain"
                h={150}
                src={donationDrop}
                draggable="true"
              />
            </Flex>
          </Flex>
        </Box>
      </Card>
      {isVisible && (
        <Card bg={defaultPink} radius="lg" mt={8}>
          <Text c={defaultYellow}>Want to Donate?</Text>
          <NumberInput
            mt={2}
            radius="lg"
            placeholder="Enter the amount"
            label="USD"
            min={1}
            value={amount}
            styles={{
              label: { color: defaultYellow },
            }}
            leftSection={<BsCurrencyDollar />}
            onChange={(value: string | number) => setAmount(value as string)}
          />
          <Button
            radius="xl"
            mt={12}
            color="blue"
            disabled={!isAuthenticated}
            onClick={() => {
              setIsDonatedModalOpen(true);
            }}
          >
            Donate
          </Button>
        </Card>
      )}
    </>
  );
};

export default DragImage;
