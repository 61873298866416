import React, { useState } from "react";
import { Button, Flex, Modal, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { FaCheck, FaExclamationCircle, FaImage } from "react-icons/fa";
import { SelectedDonationTier } from "../interfaces";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { defaultGreen, defaultPink } from "../constants";
import { uploadServiceImage } from "../state/slices/donationSlice";

interface CharacterAppearenceModalProps {
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
  setSelectedTier: (param: SelectedDonationTier) => void;
  setIsDonatedModalOpen: (param: boolean) => void;
}

const CharacterAppearenceModal: React.FC<CharacterAppearenceModalProps> = ({
  isOpen,
  setIsOpen,
  setSelectedTier,
  setIsDonatedModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const [accepted, setAccepted] = useState(false);
  const { email, name } = useAppSelector((state) => state.user);
  const { uploadedImage } = useAppSelector((state) => state.donations);

  const handleImageUpload = async (file: any) => {
    setAccepted(true);
    await dispatch(uploadServiceImage(file[0]));
  };

  const handleCharacterCreate = async () => {
    setSelectedTier({
      price: "1000.00",
      title: "Get your own character appearence",
      description: `Character appearence donation from ${name} (${email}), find the image attached below`,
      success: "Thank you. We will be coming back with your character soon",
      failed: "Donation failed. Try again.",
      message: "Stacey donation - your own character appearence",
      donationType: "Character Appearence",
      image: uploadedImage,
      amount: "1000.00",
      preferredEmail: email,
    });
    setIsOpen(false);
    setAccepted(false);
    setIsDonatedModalOpen(true);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      title="Character Appearence"
      radius="lg"
      centered
    >
      <Text size="xs" c="gray" mb={5}>
        Provide the image of the carácter you want an apperance from:
      </Text>

      <Dropzone
        onDrop={(file) => {
          handleImageUpload(file);
        }}
        accept={IMAGE_MIME_TYPE}
        radius="lg"
        multiple={false}
      >
        {" "}
        <Dropzone.Accept>
          <Flex align="center" justify="center">
            <FaCheck />
          </Flex>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Flex align="center" justify="center">
            <FaExclamationCircle />
            <Text size="sm" c={accepted ? defaultGreen : defaultPink}>
              Image not accepted
            </Text>
          </Flex>
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Flex align="center" direction="column">
            {accepted ? (
              <FaCheck color={defaultGreen} size={30} />
            ) : (
              <FaImage color={defaultPink} size={30} />
            )}

            <Text size="sm" c={accepted ? defaultGreen : defaultPink}>
              {" "}
              {accepted ? "Image accepted" : "Drop your image here"}
            </Text>
          </Flex>
        </Dropzone.Idle>
      </Dropzone>
      <Button
        mt={8}
        radius="xl"
        color={defaultPink}
        fullWidth
        onClick={() => handleCharacterCreate()}
        disabled={!accepted}
      >
        Proceed to payment
      </Button>
    </Modal>
  );
};

export default CharacterAppearenceModal;
