import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { AuthState } from "../../interfaces";
import { notifications } from "@mantine/notifications";

interface UserLoginDetails {
  email: string;
  password: string;
}

interface UserRegisterDetails {
  email: string;
  password: string;
  name: string;
}

export interface PurchasedEpisode {
  id: string;
  chapterId: string;
  watched: boolean;
  _id: string;
}

interface EpisodeDetails {
  episodeId: string;
  chapterId: string;
}

export const loginUser = createAsyncThunk(
  "user/login",
  async (user: UserLoginDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/users/login`,
      user,
      { withCredentials: true }
    );

    return response?.data;
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (user: UserRegisterDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/users`,
      user,
      { withCredentials: true }
    );

    return response?.data;
  }
);

export const getProfile = createAsyncThunk(
  "user/profile",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const response = await axios.get(
      `${process.env.REACT_APP_BE_URL}/api/users/`,
      { withCredentials: true }
    );

    return {
      purchasedEpisodes: response.data.purchasedEpisodes,
      token: state?.user?.token,
      isAdmin: response?.data?.isAdmin,
    };
  }
);

export const buyEpsiode = createAsyncThunk(
  "user/buy",
  async (details: EpisodeDetails) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BE_URL}/api/episodes/buy/${details.chapterId}/${details.episodeId}`,
      {},
      { withCredentials: true }
    );
    return response?.data;
  }
);

export const completeEpisode = createAsyncThunk(
  "episodes/complete",
  async (episodeId: string) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BE_URL}/api/episodes/complete/${episodeId}`,
      {},
      { withCredentials: true }
    );
    return response.data;
  }
);

export const checkIfAuthenticated = createAsyncThunk(
  "user/checkAuthentication",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BE_URL}/api/users/check`,
      { withCredentials: true }
    );
    return response.data;
  }
);

export const logout = createAsyncThunk("user/logout", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/users/logout`,
    { withCredentials: true }
  );
  return response.data;
});

const initialState: AuthState = {
  _id: null,
  token: null,
  name: "",
  email: "",
  loading: false,
  purchasedEpisodes: [],
  error: "",
  isAdmin: false,
  isAuthenticated: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.isAuthenticated = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state._id = action.payload._id;
        state.name = action.payload.name;
        state.purchasedEpisodes = action.payload.purchasedEpisodes;
        state.email = action.payload.email;
        state.isAdmin = action.payload.isAdmin;
        state.isAuthenticated = true;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: `Hi ${action.payload.name}!🙂`,
          message: "Welcome Back!",
        });
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action?.error?.message ?? "An error occurred";
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Wrong credentials, try again!",
        });
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.isAuthenticated = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state._id = action.payload._id;
        state.name = action.payload.name;
        state.purchasedEpisodes = action.payload.purchasedEpisodes;
        state.email = action.payload.email;
        state.isAdmin = action.payload.isAdmin;
        state.isAuthenticated = true;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: `Hi ${action.payload.name}🙂`,
          message: "Here's to watching some great content!",
        });
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message ?? "An error occurred";
        state.isAuthenticated = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Check details, try again!",
        });
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading = false;
        state.error = "";
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Error fetching profile!",
        });
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.purchasedEpisodes = action.payload.purchasedEpisodes;
        state.token = action.payload.token;
        state.purchasedEpisodes = action.payload.purchasedEpisodes;
        state.isAdmin = action.payload.isAdmin;
      })
      .addCase(buyEpsiode.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(buyEpsiode.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.purchasedEpisodes = action.payload.episodes;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Episode Purchased🙂",
          message: "Enjoy watching the episode!",
        });
      })
      .addCase(buyEpsiode.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!😭",
          message: "Episode purchase failed, try again later!",
        });
      })
      .addCase(completeEpisode.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeEpisode.fulfilled, (state, action) => {
        state.loading = false;
        state.purchasedEpisodes = action.payload.episodes;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thank you!🙂",
          message: "Thanks for watching!",
        });
      })
      .addCase(completeEpisode.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!😭",
          message: "An error occurred!",
        });
      })
      .addCase(checkIfAuthenticated.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = null;
        state.error = "";
      })
      .addCase(checkIfAuthenticated.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = "";
        state._id = action.payload.user._id;
        state.name = action.payload.user.name;
        state.email = action.payload.user.email;
        state.isAdmin = action.payload.user.isAdmin;
        state.purchasedEpisodes = action.payload.user.purchasedEpisodes;
      })
      .addCase(checkIfAuthenticated.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.isAuthenticated = null;
        state.error = "";
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = "";
        state._id = null;
        state.name = "";
        state.email = "";
        state.isAdmin = false;
        state.purchasedEpisodes = [];
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Bye!👋",
          message: "See you next time!",
        });
      })
      .addCase(logout.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to log you out!",
        });
      });
  },
});

export const { clearErrors } = authSlice.actions;

export default authSlice.reducer;
