import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { notifications } from "@mantine/notifications";

export const getFanart = createAsyncThunk("fanart/get", async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/fanart`,
    config
  );
  return response.data;
});

export const getAllFanart = createAsyncThunk("fanart/getAll", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/fanart/all`,
    { withCredentials: true }
  );
  return response.data;
});

export const approveFanart = createAsyncThunk(
  "fanart/approve",
  async (id: string) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BE_URL}/api/fanart/approve/${id}`,
      {},
      { withCredentials: true }
    );
    return response.data;
  }
);

export const submitFanart = createAsyncThunk(
  "fanart/submit",
  async (image: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/fanart`,
      {
        name: state?.user?.name,
        image,
      },
      { withCredentials: true }
    );
    dispatch(getFanart());
    return response.data;
  }
);

export const uploadFanart = createAsyncThunk(
  "fanart/upload",
  async (file: File | null, { dispatch }) => {
    const formData = new FormData();
    if (file !== null) {
      formData.append("image", file);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/upload`,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(submitFanart(response?.data?.url));
    return response.data;
  }
);

const fanartSlice = createSlice({
  name: "fanart",
  initialState: {
    fanart: [],
    allFanart: [],
    loading: false,
    success: false,
  },
  reducers: {
    clearFanartState: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFanart.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadFanart.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadFanart.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to upload fanart!",
        });
      })
      .addCase(submitFanart.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitFanart.fulfilled, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thank you!🙂",
          message: "Submitted your fanart!",
        });
      })
      .addCase(submitFanart.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Error submitting fanart!",
        });
      })
      .addCase(getFanart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFanart.fulfilled, (state, action) => {
        state.loading = false;
        state.fanart = action.payload;
      })
      .addCase(getFanart.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to load fanart!",
        });
      })
      .addCase(getAllFanart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFanart.fulfilled, (state, action) => {
        state.loading = false;
        state.allFanart = action.payload;
      })
      .addCase(getAllFanart.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to load fanart!",
        });
      })
      .addCase(approveFanart.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveFanart.fulfilled, (state, action) => {
        state.loading = false;
        state.allFanart = action.payload;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Success!🙂",
          message: "Fanart changed!",
        });
      })
      .addCase(approveFanart.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to load fanart!",
        });
      });
  },
});

export const { clearFanartState } = fanartSlice.actions;
export default fanartSlice.reducer;
