import {
  Alert,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import {
  clearErrors,
  loginUser,
  registerUser,
} from "../../state/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";

interface RegisterForm {
  email: string;
  password: string;
  name: string;
}

interface LoginForm {
  email: string;
  password: string;
}

export const SignInPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useAppSelector(
    (state) => state.user
  );
  const [mode, setMode] = useState("login");

  const registerForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      password: "",
      name: "",
    },

    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      password: (value: string) =>
        value.length < 6 ? "Password should be longer" : null,
      name: (value: string) =>
        value.length < 2 ? "Name should be longer" : null,
    },
  });

  const loginForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      password: (value: string) =>
        value.length < 6 ? "Password should be longer" : null,
    },
  });

  const handleAuthenticationLogin = (values: LoginForm) => {
    dispatch(clearErrors());
    dispatch(
      loginUser({
        ...values,
        email: values.email.toLowerCase(),
      })
    );
  };

  const handleAuthenticationRegister = (values: RegisterForm) => {
    dispatch(clearErrors());
    dispatch(
      registerUser({
        ...values,
        email: values.email.toLowerCase(),
      })
    );
  };

  const handleModeChange = () => {
    if (mode === "login") {
      loginForm.reset();
      dispatch(clearErrors());
      setMode("register");
    } else {
      registerForm.reset();
      dispatch(clearErrors());
      setMode("login");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container style={{ height: "100vh" }} fluid>
      <Flex style={{ height: "100%" }} align="center" justify="center">
        <Card w={500} shadow="xl" padding="lg" radius="xl" withBorder>
          <Text w="bold" size="xl" ta="center">
            {mode === "login" ? "Log in" : "Sign up"}
          </Text>

          <Text size="md" c="dimmed" ta="center">
            {mode === "login" ? "Log into your account" : "Create new account"}
          </Text>

          {error && (
            <Alert radius="xl" mt={4} variant="filled" color="red">
              Check your details and try again
            </Alert>
          )}

          {mode === "register" ? (
            <form
              onSubmit={registerForm.onSubmit(handleAuthenticationRegister)}
            >
              <TextInput
                mt={8}
                placeholder="Type your name here"
                radius="xl"
                key={registerForm.key("name")}
                {...registerForm.getInputProps("name")}
              />

              <TextInput
                my={8}
                placeholder="Type your email here"
                radius="xl"
                key={registerForm.key("email")}
                {...registerForm.getInputProps("email")}
              />
              <PasswordInput
                my={8}
                placeholder="Type your password here"
                radius="xl"
                key={registerForm.key("password")}
                {...registerForm.getInputProps("password")}
              />

              <Grid>
                <Grid.Col span={6}>
                  {" "}
                  <Button
                    type="submit"
                    style={{ color: "#ffffff" }}
                    variant="filled"
                    fullWidth
                    mt="md"
                    radius="xl"
                    loading={loading}
                  >
                    Create Account
                  </Button>
                </Grid.Col>
                <Grid.Col span={6}>
                  {" "}
                  <Button
                    variant="outline"
                    fullWidth
                    mt="md"
                    radius="xl"
                    onClick={handleModeChange}
                  >
                    No account? Sign up
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          ) : (
            <form onSubmit={loginForm.onSubmit(handleAuthenticationLogin)}>
              <TextInput
                my={8}
                placeholder="Type your email here"
                radius="xl"
                key={loginForm.key("email")}
                {...loginForm.getInputProps("email")}
              />
              <PasswordInput
                my={8}
                placeholder="Type your password here"
                radius="xl"
                key={loginForm.key("password")}
                {...loginForm.getInputProps("password")}
              />

              <Grid>
                <Grid.Col span={6}>
                  {" "}
                  <Button
                    loading={loading}
                    type="submit"
                    color="pink"
                    style={{ color: "#ffffff" }}
                    variant="filled"
                    fullWidth
                    mt="md"
                    radius="xl"
                  >
                    Log in
                  </Button>
                </Grid.Col>
                <Grid.Col span={6}>
                  {" "}
                  <Button
                    variant="outline"
                    fullWidth
                    mt="md"
                    radius="xl"
                    onClick={handleModeChange}
                  >
                    No account? Sign up
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          )}
        </Card>
      </Flex>
    </Container>
  );
};
