import { Button, Modal, Textarea } from "@mantine/core";
import React from "react";
import { defaultPink } from "../constants";
import { SelectedDonationTier } from "../interfaces";
import { useAppSelector } from "../state/hooks";

interface TweetModalProps {
  isOpen: boolean;
  description: string;
  setDescription: (param: string) => void;
  setIsOpen: (param: boolean) => void;
  setSelectedTier: (param: SelectedDonationTier) => void;
  setIsDonatedModalOpen: (param: boolean) => void;
}

const TweetModal: React.FC<TweetModalProps> = ({
  isOpen,
  description,
  setIsOpen,
  setDescription,
  setSelectedTier,
  setIsDonatedModalOpen,
}) => {
  const { email, name } = useAppSelector((state) => state.user);

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
        setDescription("");
      }}
      title="Tweet"
      radius="lg"
      centered
    >
      <Textarea
        value={description}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setDescription(event.target.value)
        }
        radius="lg"
        description="In the space below, describe which character you would like to write a
        tweet for you and if you want any special mention:"
        placeholder="Write here..."
      />
      <Button
        onClick={() => {
          setSelectedTier({
            price: "5.00",
            title: "Tweet Donation",
            description: `Tweet donation from ${name} (${email}), description: ${description}`,
            success:
              "Thank you for your donation, you will see it on the stacey twitter account",
            failed: "Donation failed",
            message: `Tweet donation from ${name} (${email})`,
            donationType: "Tweet",
            image: "No Image",
            amount: "5.00",
            preferredEmail: email,
          });
          setIsOpen(false);
          setIsDonatedModalOpen(true);
        }}
        mt={8}
        radius="xl"
        color={defaultPink}
        fullWidth
        disabled={description === ""}
      >
        Proceed to payment
      </Button>
    </Modal>
  );
};

export default TweetModal;
