import { Container, Text, Button, Flex } from "@mantine/core";
import NavigationBar from "../../components/NavigationBar";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../state/hooks";

const NotFound = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.user);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Flex direction="column" justify="center" align="center">
          <Text color="blue" ta="center" size="50">
            404
          </Text>
          <Text color="blue" ta="center" size="50">
            Page not found!
          </Text>
          <Button
            onClick={() => navigate("/")}
            mt={10}
            color="pink"
            radius="xl"
            size="lg"
            w={200}
          >
            Go Home
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export default NotFound;
