import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DonationDetails, TweetDetails } from "../../interfaces";
import { notifications } from "@mantine/notifications";

export const getDonations = createAsyncThunk("donations/get", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/donation`,
    { withCredentials: true }
  );
  return response.data;
});

export const tweetDonation = createAsyncThunk(
  "donations/tweet",
  async (details: TweetDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/tweet`,
      details,
      { withCredentials: true }
    );
    return response.data;
  }
);

export const uploadServiceImage = createAsyncThunk(
  "fanart/uploadServiceImage",
  async (file: File | null) => {
    const formData = new FormData();
    if (file !== null) {
      formData.append("image", file);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/upload`,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }
);

export const completeDonation = createAsyncThunk(
  "donations/complete",
  async (id: string, { dispatch }) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BE_URL}/api/donation/${id}`,
      {},
      { withCredentials: true }
    );
    await dispatch(getDonations());
    return response.data;
  }
);

export const submitDonation = createAsyncThunk(
  "donations/submit",
  async (details: DonationDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/donation`,
      details,
      { withCredentials: true }
    );
    return response.data;
  }
);

const donationSlice = createSlice({
  name: "donation",
  initialState: {
    donations: [],
    loading: false,
    error: null as true | false | null,
    uploadedImage: "",
  },
  reducers: {
    resetState: (state) => {
      state.error = null;
      state.uploadedImage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDonations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDonations.fulfilled, (state, action) => {
        state.loading = false;
        state.donations = action.payload;
      })
      .addCase(getDonations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadServiceImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadServiceImage.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadedImage = action.payload.url;
      })
      .addCase(uploadServiceImage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(tweetDonation.pending, (state) => {
        state.loading = true;
      })
      .addCase(tweetDonation.fulfilled, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!😭",
          message: "Error tweeting!",
        });
      })
      .addCase(tweetDonation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(submitDonation.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(submitDonation.fulfilled, (state) => {
        state.error = false;
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thanks!🙂",
          message: "Thanks for your contribution!",
        });
      })
      .addCase(submitDonation.rejected, (state) => {
        state.error = true;
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to submit donation!",
        });
      })
      .addCase(completeDonation.pending, (state) => {
        state.loading = true;
      })
      .addCase(completeDonation.fulfilled, (state, action) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thanks!🙂",
          message: "Completed service!",
        });
      })
      .addCase(completeDonation.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetState } = donationSlice.actions;

export default donationSlice.reducer;
