import { combineReducers } from "redux";
import authReducer from "../slices/authSlice";
import paymentReducer from "../slices/paymentSlice";
import forumReducer from "../slices/forumSlice";
import appReducer from "../slices/appSlice";
import fanartReducer from "../slices/fanartSlice";
import episodeReducer from "../slices/episodeSlice";
import donationReducer from "../slices/donationSlice";

const rootReducer = combineReducers({
  user: authReducer,
  payment: paymentReducer,
  forum: forumReducer,
  app: appReducer,
  fanart: fanartReducer,
  episodes: episodeReducer,
  donations: donationReducer,
});

export default rootReducer;
