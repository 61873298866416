import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Text,
  Title,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { defaultGreen, defaultPink } from "../../constants";
import { FaCheckCircle, FaDonate, FaExclamationTriangle } from "react-icons/fa";
import { SelectedDonationTier } from "../../interfaces";
import SuccessDonationModal from "../../popups/SuccessDonationModal";
import PayModal from "../../popups/PayModal";
import { resetState, submitDonation } from "../../state/slices/donationSlice";
import TweetModal from "../../popups/TweetModal";
import CreateCharacterModal from "../../popups/CreateCharacterModal";
import CharacterAppearenceModal from "../../popups/CharacterAppearenceModal";

const ForYouPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [description, setDescription] = useState("");
  const [isDonatedModalOpen, setIsDonatedModalOpen] = useState(false);
  const [successDonationOpen, setSuccessDonationOpen] = useState(false);
  const { token, isAuthenticated, email } = useAppSelector(
    (state) => state.user
  );
  const [isTweetDonationModalOpen, setIsTweetDonationModalOpen] =
    useState(false);
  const [
    isCharacterCreationDonationModalOpen,
    setIsCharacterCreationDonationModalOpen,
  ] = useState(false);
  const [isCharacterAppearenceModalOpen, setIsCharacterAppearenceModalOpen] =
    useState(false);
  const [selectedTier, setSelectedTier] = useState<SelectedDonationTier>({
    price: "0",
    title: "",
    description: "",
    success: "",
    failed: "",
    message: "",
    donationType: "Tweet",
    image: "",
    amount: "",
    preferredEmail: email,
  });

  const handleOnDonationSuccess = async () => {
    await dispatch(
      submitDonation({
        message: selectedTier.description,
        donationType: selectedTier.donationType,
        image: selectedTier.image,
        amount: selectedTier.amount,
        preferredEmail: email,
      })
    );
    setSelectedTier({
      price: "0",
      title: "",
      description: "",
      success: "",
      failed: "",
      message: "",
      donationType: "Tweet",
      image: "",
      amount: "",
      preferredEmail: email,
    });

    setIsDonatedModalOpen(false);
    setSuccessDonationOpen(true);
    setDescription("");
    dispatch(resetState());
  };

  useEffect(() => {
    if (token === "") {
      navigate("/auth");
    }
  }, [dispatch, token, navigate]);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Flex my={20} justify="center">
          <Title order={1} c="pink">
            For You
          </Title>
        </Flex>
        {!isAuthenticated && (
          <Alert
            radius="lg"
            mt={10}
            mb={10}
            variant="light"
            color="blue"
            title="Warning!"
            icon={<FaExclamationTriangle />}
          >
            <Text> You need to be logged in get services!</Text>
            <Button
              color="pink"
              mt={8}
              radius="xl"
              onClick={() => navigate("/auth")}
            >
              Log in now
            </Button>
          </Alert>
        )}
        <Grid>
          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex direction="column" align="center" justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  1
                </Avatar>
                <Text c="yellow" size="xl" ta="center">
                  Tweet
                </Text>
                <Text c="white" size="lg" ta="center">
                  Want a tweet from a character?
                </Text>
                <Button
                  mt={10}
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  disabled={!isAuthenticated}
                  onClick={() => {
                    setIsTweetDonationModalOpen(true);
                  }}
                  fullWidth
                >
                  Get | 5.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>

          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  2
                </Avatar>
              </Flex>
              <Text c="yellow" size="xl" ta="center">
                Character Creation
              </Text>
              <Text c="white" size="lg" ta="center">
                What about your own character?
              </Text>

              <Flex justify="center" mt={10}>
                <Button
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  disabled={!isAuthenticated}
                  onClick={() => {
                    setIsCharacterCreationDonationModalOpen(true);
                  }}
                  fullWidth
                >
                  Get | 100.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  3
                </Avatar>
              </Flex>
              <Text c="yellow" size="xl" ta="center">
                Character Appearence
              </Text>
              <Text c="white" size="lg" ta="center">
                Want your avatar to appear?
              </Text>
              <Flex justify="center" mt={10}>
                <Button
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  disabled={!isAuthenticated}
                  onClick={() => {
                    setIsCharacterAppearenceModalOpen(true);
                  }}
                  fullWidth
                >
                  Get | 1000.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>
        </Grid>
        <TweetModal
          isOpen={isTweetDonationModalOpen}
          description={description}
          setSelectedTier={setSelectedTier}
          setDescription={setDescription}
          setIsOpen={setIsTweetDonationModalOpen}
          setIsDonatedModalOpen={setIsDonatedModalOpen}
        />
        <CharacterAppearenceModal
          isOpen={isCharacterAppearenceModalOpen}
          setSelectedTier={setSelectedTier}
          setIsOpen={setIsCharacterAppearenceModalOpen}
          setIsDonatedModalOpen={setIsDonatedModalOpen}
        />
        <CreateCharacterModal
          isOpen={isCharacterCreationDonationModalOpen}
          description={description}
          setSelectedTier={setSelectedTier}
          setDescription={setDescription}
          setIsOpen={setIsCharacterCreationDonationModalOpen}
          setIsDonatedModalOpen={setIsDonatedModalOpen}
        />
        <SuccessDonationModal
          title=""
          description="Thank you!"
          isOpen={successDonationOpen}
          setIsOpen={setSuccessDonationOpen}
          icon={<FaCheckCircle size={40} color={defaultGreen} />}
        />

        <PayModal
          price={selectedTier.price}
          title={selectedTier.title}
          isCompleted="NOT"
          isOpen={isDonatedModalOpen}
          setIsCompleted={() => "NOT"}
          setIsOpen={setIsDonatedModalOpen}
          description={selectedTier.description}
          successMessage={selectedTier.success}
          failedMessage={selectedTier.failed}
          onSuccess={handleOnDonationSuccess}
        />
      </Container>
    </>
  );
};

export default ForYouPage;
