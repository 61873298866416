import { Flex, Loader, Overlay } from "@mantine/core";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean | null;
  loading: boolean;
}

const ProtectedRoute = ({
  element,
  isAuthenticated,
  loading,
}: ProtectedRouteProps) => {
  if (loading) {
    return (
      <Overlay style={{ height: "100vh" }} color="#000">
        <Flex justify="center" align="center" h="100%">
          <Loader color="pink" size="xl" />
        </Flex>
      </Overlay>
    );
  }

  const returnElement = () => {
    if (isAuthenticated) {
      return element;
    } else {
      if (isAuthenticated === null) {
        return (
          <Overlay style={{ height: "100vh" }} color="#000">
            <Flex justify="center" align="center" h="100%">
              <Loader color="pink" size="xl" />
            </Flex>
          </Overlay>
        );
      } else {
        return <Navigate to="/auth" />;
      }
    }
  };

  return returnElement();
};

export default ProtectedRoute;
