import styled from "styled-components";

export const NavigationBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: rgba(220, 230, 78, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
`;

export const PopUpContainer = styled.span`
  width: 100%;
  :hover {
    transform: scale(1.02);
  }
`;
