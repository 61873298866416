import { Card, Text } from "@mantine/core";
import React from "react";
import { defaultPink } from "../../constants";

interface EmptyProps {
  title: string;
}

const Empty: React.FC<EmptyProps> = ({ title }) => {
  return (
    <Card shadow="xl" style={{ backgroundColor: defaultPink }} radius="lg">
      <Text color="yellow" size="lg" ta="center">
        {title}
      </Text>
    </Card>
  );
};

export default Empty;
