import {
  Alert,
  Button,
  Container,
  FileButton,
  Flex,
  Grid,
  Loader,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import {
  FaExclamationTriangle,
  FaHandHolding,
  FaPaintBrush,
} from "react-icons/fa";
import FanArtCard from "../../components/FanartCard";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { getFanart, uploadFanart } from "../../state/slices/fanartSlice";
import { Art } from "../../interfaces";
import { useNavigate } from "react-router-dom";

const Fanart = () => {
  const dispatch = useAppDispatch();
  const { fanart, loading } = useAppSelector((state) => state.fanart);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [opened, setIsOpened] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const handleFanartSubmission = () => {
    dispatch(uploadFanart(file));
    setIsOpened(false);
    setFile(null);
  };

  useEffect(() => {
    dispatch(getFanart());
  }, [dispatch]);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Modal
          opened={opened}
          onClose={() => setIsOpened(false)}
          title="Submit fanart"
          radius="lg"
          centered
          closeOnClickOutside={false}
        >
          {file && (
            <Text mb={10} size="sm" ta="center" variant="text" c="pink">
              {file.name}
            </Text>
          )}
          <FileButton onChange={setFile} accept="image/png,image/jpeg">
            {(props) => (
              <Button
                size="xs"
                variant="outline"
                radius="xl"
                fullWidth
                leftSection={<FaPaintBrush />}
                {...props}
              >
                Upload Art
              </Button>
            )}
          </FileButton>

          <Button
            leftSection={<FaHandHolding />}
            mt={12}
            color="pink"
            radius="xl"
            onClick={() => handleFanartSubmission()}
            fullWidth
            type="submit"
            disabled={file ? false : true}
          >
            Submit Fanart
          </Button>
        </Modal>
        <Flex direction="column" my={20} justify="center">
          <Title ta="center" c="pink" order={1}>
            Fanart
          </Title>
          {isAuthenticated && (
            <Button
              leftSection={<FaHandHolding />}
              mt={12}
              size="lg"
              color="pink"
              radius="xl"
              onClick={() => setIsOpened(true)}
            >
              Submit Fanart
            </Button>
          )}
        </Flex>

        {!isAuthenticated && (
          <Alert
            radius="lg"
            mt={20}
            variant="light"
            color="blue"
            title="Warning!"
            icon={<FaExclamationTriangle />}
          >
            <Text> You need to be logged in to submit fanart!</Text>
            <Button
              color="pink"
              mt={8}
              radius="xl"
              onClick={() => navigate("/auth")}
            >
              Log in now
            </Button>
          </Alert>
        )}

        {loading ? (
          <Flex direction="row" justify="center" align="center">
            <Loader size="xl" color="pink" />
          </Flex>
        ) : (
          <Grid mt={10}>
            {(fanart || []).map((art: Art) => {
              return (
                <Grid.Col span={{ base: 4, xs: 12, sm: 6, md: 4 }}>
                  <FanArtCard
                    _id={art._id}
                    image={art.image}
                    name={art.name}
                    date={art.createdAt}
                    approved={art.approved}
                  />
                </Grid.Col>
              );
            })}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Fanart;
