import { Card, Image, Text, Button, Group } from "@mantine/core";
import { FaPlayCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectEpisode } from "../../state/slices/episodeSlice";
import { defaultYellow } from "../../constants";

interface EpisodeCardProps {
  _id: string;
  image: string;
  title: string;
  description: string;
  price: Number;
  createdAt: Date;
  chapterId: string;
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({
  _id,
  image,
  title,
  description,
  price,
  createdAt,
  chapterId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEpisodeSelection = () => {
    navigate(`/episodes/${chapterId}/${_id}`);
    dispatch(
      selectEpisode({
        _id,
        thumbnail: image,
        name: title,
        description,
        price,
        createdAt,
      })
    );
  };

  return (
    <Card
      style={{ backgroundColor: defaultYellow }}
      shadow="xl"
      radius="lg"
      withBorder
    >
      <Card.Section>
        <Image src={image} height={180} alt="thumbnail" />
      </Card.Section>

      <Group p="apart" mt="md" mb="xs">
        <Text w={500}>{title}</Text>
      </Group>

      <Text size="sm" color="dimmed">
        {description?.substring(0, 100) + "..."}
      </Text>

      <Button
        leftSection={<FaPlayCircle />}
        color="pink"
        fullWidth
        mt="md"
        radius="xl"
        onClick={() => handleEpisodeSelection()}
      >
        Watch Episode
      </Button>
    </Card>
  );
};

export default EpisodeCard;
