import { Flex, Modal, Text } from "@mantine/core";
import React from "react";

interface SuccessDonationModalProps {
  title: string;
  icon: JSX.Element;
  description: string;
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
}

const SuccessDonationModal: React.FC<SuccessDonationModalProps> = ({
  title,
  description,
  icon,
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      title={title}
      radius="lg"
      centered
    >
      <Flex justify="center">{icon}</Flex>
      <Text color="pink" size="lg" mt={6} ta="center">
        {description}
      </Text>
    </Modal>
  );
};

export default SuccessDonationModal;
