import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";

import { Button, Flex, MantineProvider, Modal, Text } from "@mantine/core";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { SignInPage } from "./pages/Authentication";
import HomePage from "./pages/Home";
import EpisodesPage from "./pages/Episodes";
import NotFound from "./pages/NotFound";
import EpisodePage from "./pages/Episode";
import Fanart from "./pages/Fanart";
import DonationPage from "./pages/Donations";
import Fuockram from "./pages/Forums";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import { closeIsLogInModel } from "./state/slices/appSlice";
import Admin from "./pages/Admin";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import { useEffect } from "react";
import { checkIfAuthenticated } from "./state/slices/authSlice";
import { Notifications } from "@mantine/notifications";
import ForYouPage from "./pages/ForYou";

function App() {
  const dispatch = useAppDispatch();
  const { isLogInModelOpen, message } = useAppSelector((state) => state.app);
  const { isAdmin, loading, isAuthenticated } = useAppSelector(
    (state) => state.user
  );

  const handleLogInNavigation = () => {
    //navigate("/auth");
    dispatch(closeIsLogInModel());
  };

  const handleOnLogInModalClose = () => {
    //navigate(-1);
    dispatch(closeIsLogInModel());
  };

  useEffect(() => {
    dispatch(checkIfAuthenticated());
  }, [dispatch]);

  return (
    <MantineProvider
      theme={{
        defaultGradient: { from: "#4bb1f7", to: "#fb92b0", deg: 20 },
        breakpoints: {
          xs: "370px",
          sm: "665px",
          md: "1000",
          lg: "1200",
          xl: "1400",
        },
        colors: {
          blue: [
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
            "#108ee4",
          ],
          pink: [
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
            "#e64980",
          ],
          yellow: [
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
            "#dce64e",
          ],
        },
      }}
    >
      <Notifications />
      <ToastContainer />
      <Modal
        centered
        opened={isLogInModelOpen}
        onClose={() => handleOnLogInModalClose()}
        title=""
        radius="lg"
      >
        <Text ta="center">{message}</Text>
        <Flex mt={10} justify="center">
          <Button
            size="md"
            color="blue"
            radius="xl"
            onClick={() => handleLogInNavigation()}
          >
            Log in
          </Button>
        </Flex>
      </Modal>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/episodes" element={<EpisodesPage />} />
          <Route path="/fanart" element={<Fanart />} />
          <Route path="/fuockroom" element={<Fuockram />} />
          <Route path="/auth" element={<SignInPage />} />
          <Route
            path="/episodes/:chapterId/:episodeId"
            element={
              <ProtectedRoute
                loading={loading}
                isAuthenticated={isAuthenticated}
                element={<EpisodePage />}
              />
            }
          />
          <Route path="/donations" element={<DonationPage />} />
          <Route path="/foryou" element={<ForYouPage />} />
          {isAdmin && <Route path="/admin" element={<Admin />} />}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
