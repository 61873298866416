import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isLogInModelOpen: false,
    message: "",
  },
  reducers: {
    clearAppState: (state) => {
      state.isLogInModelOpen = false;
      state.message = "";
    },
    closeIsLogInModel: (state) => {
      state.isLogInModelOpen = false;
      state.message = "";
    },
    openIsLogInModel: (state, action) => {
      state.isLogInModelOpen = true;
      state.message = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { clearAppState, closeIsLogInModel, openIsLogInModel } =
  appSlice.actions;
export default appSlice.reducer;
