import { Alert, Button, Container, Flex, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import NavigationBar from "../../components/NavigationBar";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";
import { defaultPink } from "../../constants";
import DragImage from "../../components/DragImage";
import { FaExclamationTriangle } from "react-icons/fa";

const DonationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token, isAuthenticated } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (token === "") {
      navigate("/auth");
    }
  }, [dispatch, token, navigate]);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Flex my={20} justify="center">
          <Title order={1} c="pink">
            Donation Tiers
          </Title>
        </Flex>

        <Text ta="center" c={defaultPink}>
          Drag the money onto the thong to donate!
        </Text>
        {!isAuthenticated && (
          <Alert
            radius="lg"
            mt={20}
            variant="light"
            color="blue"
            title="Warning!"
            icon={<FaExclamationTriangle />}
          >
            <Text> You need to be logged in to donate!</Text>
            <Button
              color="pink"
              mt={8}
              radius="xl"
              onClick={() => navigate("/auth")}
            >
              Log in now
            </Button>
          </Alert>
        )}
        <DragImage isAuthenticated={isAuthenticated} />
      </Container>
    </>
  );
};

export default DonationPage;
