import { Avatar, Flex, Modal, Text } from "@mantine/core";
import { PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";
import { useAppDispatch } from "../state/hooks";
import { createPayment, executePayment } from "../state/slices/paymentSlice";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import { notifications } from "@mantine/notifications";

interface PayModalProps {
  isCompleted: "NOT" | "SUCCESS";
  isOpen: boolean;
  price: string;
  description: string;
  successMessage: string;
  failedMessage: string;
  title: string;
  setIsCompleted: (param: "NOT" | "SUCCESS") => void;
  setIsOpen: (param: boolean) => void;
  onSuccess: (param: string) => void;
}

const PayModal: React.FC<PayModalProps> = ({
  isCompleted,
  isOpen,
  setIsCompleted,
  setIsOpen,
  price,
  description,
  successMessage,
  failedMessage,
  onSuccess,
  title,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      opened={isOpen}
      onClose={() => [setIsOpen(false), setIsCompleted("NOT")]}
      title={title}
      radius="lg"
      centered
    >
      {isCompleted === "NOT" ? (
        <PayPalButtons
          createOrder={() => {
            return dispatch(
              createPayment({
                price: price,
                description: description,
              })
            )
              .unwrap()
              .then((orderID) => {
                return orderID;
              })
              .catch((error) => {
                console.error("Error creating order:", error);
              });
          }}
          onApprove={(data) => {
            return dispatch(executePayment(data.orderID))
              .unwrap()
              .then((details) => {
                onSuccess(details.result.status);
              })
              .catch(() => {
                notifications.show({
                  position: "top-right",
                  radius: "lg",
                  color: "red",
                  title: "Oops!🥲",
                  message: "Error creating payment!",
                });
              });
          }}
        />
      ) : (
        <Flex align="center" direction="column">
          {isCompleted === "SUCCESS" ? (
            <>
              <Avatar color="pink" mr={4} variant="light" size="lg" radius="xl">
                <FaCheck />
              </Avatar>
              <Text color="blue" size="22" ta="center">
                {successMessage}
              </Text>
            </>
          ) : (
            <>
              {" "}
              <Avatar color="pink" mr={4} variant="light" size="lg" radius="xl">
                <FaExclamationCircle />
              </Avatar>
              <Text color="blue" size="22" ta="center">
                {failedMessage}
              </Text>
            </>
          )}
        </Flex>
      )}
    </Modal>
  );
};

export default PayModal;
