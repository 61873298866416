import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Loader,
  Modal,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  createThread,
  getThreads,
  replyToThread,
} from "../../state/slices/forumSlice";
import { Reply, Thread } from "../../interfaces/index";
import moment from "moment";
import { AiFillMessage } from "react-icons/ai";
import { FaExclamationTriangle, FaUserAlt } from "react-icons/fa";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

interface ThreadForm {
  title: string;
  description: string;
}

interface CommentForm {
  comment: string;
}

const Fuockram = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedThread, setSelectedThread] = useState<string>("");
  const [mode, setMode] = useState<"Thread" | "Comment">("Thread");
  const { threads, loading } = useAppSelector((state) => state.forum);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const threadForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      title: "",
      description: "",
    },
    validate: {
      title: (value: any) =>
        value.length < 2 ? "Title should be longer" : null,
      description: (value: any) =>
        value.length < 2 ? "Description should be longer" : null,
    },
  });

  const commentForm = useForm({
    mode: "uncontrolled",
    initialValues: {
      comment: "",
    },
    validate: {
      comment: (value: any) =>
        value.length < 2 ? "Comment should be longer" : null,
    },
  });

  useEffect(() => {
    dispatch(getThreads());
  }, [dispatch]);

  const handleForumMode = (mode: string) => {
    if (mode === "Thread") {
      setMode("Thread");
      setIsOpened(true);
    } else {
      setMode("Comment");
      setIsOpened(true);
    }
  };

  const handleThreadFormSubmission = async (values: ThreadForm) => {
    const { title, description } = values;
    await dispatch(createThread({ title, description }));
    setIsOpened(false);
    threadForm.reset();
  };

  const handleCommentFormSubmission = async (values: CommentForm) => {
    const { comment } = values;
    await dispatch(replyToThread({ comment, id: selectedThread }));
    setIsOpened(false);
    commentForm.reset();
  };

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Modal
          opened={isOpened}
          onClose={() => {
            setIsOpened(false);
            threadForm.reset();
            commentForm.reset();
          }}
          title={
            mode === "Comment" ? "Comment on this thread" : "Create new thread"
          }
          centered
          radius="lg"
        >
          {mode === "Thread" ? (
            <>
              <form
                onSubmit={threadForm.onSubmit((values: any) =>
                  handleThreadFormSubmission(values)
                )}
              >
                <TextInput
                  radius="lg"
                  label="Title"
                  placeholder="Type thread title..."
                  {...threadForm.getInputProps("title")}
                />
                <Textarea
                  mt={10}
                  radius="lg"
                  label="Description"
                  placeholder="Type your comment..."
                  {...threadForm.getInputProps("description")}
                />
                <Button
                  size="md"
                  mt={10}
                  variant="filled"
                  radius="xl"
                  color="pink"
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </form>
            </>
          ) : (
            <form
              onSubmit={commentForm.onSubmit((values: any) =>
                handleCommentFormSubmission(values)
              )}
            >
              <Textarea
                radius="lg"
                label="Comment"
                placeholder="Type your comment..."
                {...commentForm.getInputProps("comment")}
              />
              <Button
                size="md"
                mt={10}
                variant="filled"
                radius="xl"
                color="blue"
                onClick={() => handleForumMode("Comment")}
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Modal>

        <Flex my={20} justify="center">
          <Title c="pink" order={1}>
            Fuockroom
          </Title>
        </Flex>
        <Text my={10} size="md" ta="left" c="pink">
          Welcome to the Fuockroom, the place where you can express all your
          feelings that, while not important and that no one really cares about,
          might just become a topic of debate with other idiots using this
          group. The one who insults the most wins!
        </Text>
        {isAuthenticated ? (
          <Flex justify="flex-end">
            <Button
              leftSection={<AiFillMessage />}
              size="md"
              variant="filled"
              radius="xl"
              color="blue"
              onClick={() => handleForumMode("Thread")}
            >
              Start a thread
            </Button>
          </Flex>
        ) : (
          <Alert
            radius="lg"
            mt={20}
            variant="light"
            color="blue"
            title="Warning!"
            icon={<FaExclamationTriangle />}
          >
            <Text> You need to be logged in to join the chat!</Text>
            <Button
              color="pink"
              mt={8}
              radius="xl"
              onClick={() => navigate("/auth")}
            >
              Log in now
            </Button>
          </Alert>
        )}

        {loading ? (
          <Flex direction="row" justify="center" align="center">
            <Loader size="xl" color="pink" />
          </Flex>
        ) : (
          <>
            {(threads || []).map((thread: Thread) => {
              return (
                <Card
                  style={{ backgroundColor: "#e64980" }}
                  mt={8}
                  radius="lg"
                  shadow="xl"
                >
                  <Flex direction="row" align="center" justify="space-between">
                    <Flex direction="row" align="center">
                      <Avatar
                        size="md"
                        variant="filled"
                        color="blue"
                        mr={4}
                        radius="xl"
                      >
                        <FaUserAlt />
                      </Avatar>
                      <Text c="yellow">{thread.name}</Text>
                    </Flex>

                    <Text c="yellow" mt={10} size="md">
                      {moment(thread.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </Text>
                  </Flex>
                  <Text c="yellow" mt={6}>
                    {thread.title}
                  </Text>
                  <Text c="white" mt={10} size="md">
                    {thread.description}
                  </Text>
                  <Flex my={10} direction="row" justify="space-between">
                    <Text c="white" mt={6}>
                      Replies {thread.replies.length}
                    </Text>
                    <Button
                      leftSection={<AiFillMessage />}
                      size="xs"
                      variant="filled"
                      radius="xl"
                      color="blue"
                      onClick={() => {
                        setSelectedThread(thread._id);
                        handleForumMode("Comment");
                      }}
                    >
                      Reply
                    </Button>
                  </Flex>
                  <Box ml={10}>
                    {thread?.replies.map((reply: Reply) => {
                      return (
                        <>
                          <Divider my={6} />
                          <Flex
                            direction="row"
                            align="center"
                            justify="space-between"
                          >
                            <Flex>
                              <Avatar
                                size="sm"
                                variant="filled"
                                color="blue"
                                mr={4}
                                radius="xl"
                              >
                                {" "}
                                <FaUserAlt />
                              </Avatar>
                              <Text size="sm" c="yellow">
                                {reply.name}
                              </Text>
                            </Flex>
                            <Text size="sm" c="yellow">
                              {moment(reply.createdAt).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </Text>{" "}
                          </Flex>
                          <Text mt={6} size="sm" color="white">
                            {reply.comment}
                          </Text>
                          <Divider my={6} />
                        </>
                      );
                    })}
                  </Box>
                </Card>
              );
            })}
          </>
        )}
      </Container>
    </>
  );
};

export default Fuockram;
