import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { notifications } from "@mantine/notifications";

interface ReplyInfo {
  comment: string;
  id: string;
}

interface ThreadInfo {
  title: string;
  description: string;
}

export const getThreads = createAsyncThunk("forum/getAll", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/forum/`,
    { withCredentials: true }
  );
  return response.data;
});

export const getThread = createAsyncThunk("forum/get", async (id: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/forum/${id}`,
    { withCredentials: true }
  );
  return response.data;
});

export const createThread = createAsyncThunk(
  "forum/create",
  async (threadInfo: ThreadInfo, { getState, dispatch }) => {
    const state = getState() as RootState;
    const newThread = {
      title: threadInfo.title,
      description: threadInfo.description,
      name: state?.user?.name,
      replies: [],
      user: state.user._id,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/forum`,
      newThread,
      { withCredentials: true }
    );
    dispatch(getThreads());
    return response.data;
  }
);

export const replyToThread = createAsyncThunk(
  "forum/reply",
  async (replyInfo: ReplyInfo, { getState, dispatch }) => {
    const state = getState() as RootState;

    const newComment = {
      user: state?.user?._id,
      name: state?.user?.name,
      comment: replyInfo.comment,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/forum/${replyInfo.id}/comment`,
      newComment,
      { withCredentials: true }
    );
    dispatch(getThreads());
    return response.data;
  }
);

const forumSlice = createSlice({
  name: "auth",
  initialState: {
    threads: [],
    loading: false,
    error: "",
    success: false,
    thread: {
      title: "",
      description: "",
      name: "",
      replies: [],
      user: "",
      _id: "",
    },
  },
  reducers: {
    clearForumState: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreads.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getThreads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.threads = action.payload;
      })
      .addCase(getThreads.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
      })
      .addCase(getThread.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getThread.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(getThread.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
      })
      .addCase(createThread.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createThread.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.success = true;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thanks!🙂",
          message: "Created Thread!",
        });
      })
      .addCase(createThread.rejected, (state) => {
        state.loading = false;
        state.error = "Error creating thread";
      })
      .addCase(replyToThread.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(replyToThread.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Success!🙂",
          message: "Reply sent!",
        });
      })
      .addCase(replyToThread.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🥲",
          message: "An error occurred!",
        });
      });
  },
});

export const { clearForumState } = forumSlice.actions;
export default forumSlice.reducer;
