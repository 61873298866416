import React, { useEffect, useState } from "react";
import {
  buyEpsiode,
  completeEpisode,
  PurchasedEpisode,
} from "../../state/slices/authSlice";
import {
  Button,
  Card,
  Flex,
  Overlay,
  Text,
  Box,
  Alert,
  Divider,
} from "@mantine/core";
import { FaExclamationTriangle, FaVideoSlash } from "react-icons/fa";
import { defaultBlack, defaultPink, defaultYellow } from "../../constants";
import { useAppDispatch } from "../../state/hooks";
import ReactPlayer from "react-player";
import PayModal from "../../popups/PayModal";
import moment from "moment";

interface VideoProps {
  id: string;
  url: string;
  width?: string;
  height?: string;
  purchasedEpisodes: PurchasedEpisode[];
  thumbnail: string;
  chapterId: string;
  createdAt: string;
  description: string;
}

const Video: React.FC<VideoProps> = ({
  url,
  purchasedEpisodes,
  id,
  chapterId,
  thumbnail,
  description,
  createdAt,
}) => {
  const dispatch = useAppDispatch();
  const [isPurchased, setIsPurchased] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpenModal] = useState(false);

  const ifPurchased = (purchasedEpisodes || []).filter(
    (e: PurchasedEpisode) => {
      return e.id === id;
    }
  );

  const handleEnded = () => {
    dispatch(completeEpisode(id));
  };

  const handlePurchaseModal = (success: string) => {
    if (success === "COMPLETED") {
      dispatch(
        buyEpsiode({
          episodeId: id,
          chapterId,
        })
      );
      setIsPurchaseModalOpenModal(false);
    }
  };

  useEffect(() => {
    if (ifPurchased.length) {
      setIsPurchased(true);
    } else {
      setIsPurchased(false);
    }
  }, [ifPurchased]);

  return (
    <>
      <PayModal
        price="4.99"
        title="Buy this episode"
        isCompleted="NOT"
        isOpen={isPurchaseModalOpen}
        setIsCompleted={() => "NOT"}
        setIsOpen={setIsPurchaseModalOpenModal}
        description="Stacey Episode Purchase"
        successMessage="Thank you for purchasing the episode"
        failedMessage="Purchase failed. Try again."
        onSuccess={handlePurchaseModal}
      />

      <Card
        radius="lg"
        p={0}
        style={{ backgroundColor: defaultBlack }}
        shadow="xl"
      >
        {isPurchased ? (
          <Flex justify="center">
            <ReactPlayer
              width="100%"
              onEnded={handleEnded}
              config={{
                vimeo: {
                  playerOptions: {
                    title: 0,
                    byline: 0,
                    portrait: 0,
                    controls: true,
                    transparent: true,
                    dnt: true,
                  },
                },
              }}
              url={url}
              controls
            />
          </Flex>
        ) : (
          <Box h={360}>
            {
              <Overlay color="#2e2e2e" blur={1}>
                <Flex
                  direction="column"
                  h="100%"
                  align="center"
                  justify="center"
                >
                  <FaVideoSlash color="white" size={50} />

                  <Text size="xl" color="white" ta="center">
                    You have not purchased this episode!
                  </Text>
                  <Text mt={5} size="md" color="white" ta="center">
                    Purchase for one time viewing
                  </Text>
                  <Button
                    mt={10}
                    size="md"
                    color="pink"
                    radius="xl"
                    variant="filled"
                    onClick={() => setIsPurchaseModalOpenModal(true)}
                  >
                    Buy Now | USD 4.99
                  </Button>
                </Flex>
              </Overlay>
            }
          </Box>
        )}
      </Card>

      {isPurchased && (
        <>
          <Alert
            radius="lg"
            mt={20}
            variant="filled"
            color="orange"
            title="Warning!"
            icon={<FaExclamationTriangle />}
          >
            You have purchased the episode to view one time. Seeking through
            video and reaching the end will count as a one time view.
          </Alert>
        </>
      )}
      <Card style={{ backgroundColor: defaultPink }} mt={20} radius="lg">
        <Text size="xl" ta="left" color={defaultYellow}>
          Uploaded on {moment(createdAt).format("DD-MM-YYYY hh:mm A")}
        </Text>
        <Divider my={10} color={defaultYellow} />
        <Text mt={6} color={defaultYellow}>
          {description}
        </Text>
      </Card>
    </>
  );
};

export default Video;
